import { useTranslation, Icon } from "krl-alfred";
import {Link, useNavigate} from "react-router-dom";
import { formatDate } from "../../../utils/date";
import Listing from "../../Listing";
import React from "react";

const List = (props) => {
  const { t } = useTranslation();

  const routeLink = (record) => `/customer/${record?.id}`

  const columns = [
    {
      key: "id",
      title: t("LIST.ID"),
      dataIndex: "id",
      render: (_, data) => <Link to={routeLink(data)}>{data?.id}</Link>
    },
    {
      key: "firstName",
      title: t("CUSTOMER.FIRST_NAME"),
      dataIndex: "firstName",
      render: (_, data) => <Link to={routeLink(data)}>{data?.firstName}</Link>
    },
    {
      key: "lastName",
      title: t("CUSTOMER.LAST_NAME"),
      dataIndex: "lastName",
      render: (_, data) => <Link to={routeLink(data)}>{data?.lastName}</Link>
    },
    {
      key: "identificationNumber",
      title: t("CUSTOMER.IDENTIFICATION_NUMBER"),
      dataIndex: "identificationNumber",
      render: (_, data) => <Link to={routeLink(data)}>{data?.identificationNumber}</Link>
    },
    {
      key: "dateOfBirth",
      title: t("CUSTOMER.DATE_OF_BIRTH"),
      dataIndex: "dateOfBirth",
      render: (_, data) => <Link to={routeLink(data)}>{formatDate(data?.dateOfBirth)}</Link>
    },
    {
      key: "isDeleted",
      title: t("CUSTOMER.DELETED"),
      dataIndex: "isDeleted",
      render: (_, data) => <Link to={routeLink(data)}>{data?.isDeleted ? t("CUSTOMER.DELETED") : t("CUSTOMER.NOT_DELETED")}</Link>
    },
    {
      key: "isVerified",
      title: t("CUSTOMER.IS_VERIFIED"),
      dataIndex: "isVerified",
      render: (_, data) => <Link to={routeLink(data)}>{data?.isVerified ?
          <div className="status"><Icon color="var(--green)" name="CircleFilled"/>{t("CUSTOMER.VERIFIED")}</div> :
          <div className="status"><Icon color="var(--red)" name="CircleFilled"/>{t("CUSTOMER.NOT_VERIFIED")}
          </div>}</Link>
    },
    {
      key: "email",
      title: t("CUSTOMER.E_MAIL"),
      dataIndex: "email",
      render: (_, data) => <Link to={routeLink(data)}>{data?.email}</Link>
    },
    {
      key: "phone",
      title: t("CUSTOMER.PHONE_NUMBER"),
      dataIndex: "phone",
      render: (_, data) => <Link to={routeLink(data)}>{data?.phone}</Link>
    },
    {
      key: "roles",
      title: t("CUSTOMER.ROLE"),
      dataIndex: "roles",
      render: (_, data) => <Link to={routeLink(data)}>{data?.roles}</Link>
    },
    {
      key: "isActive",
      title: t("CUSTOMER.IS_ACTIVE"),
      dataIndex: "isActive",
      render: (_, data) => <Link to={routeLink(data)}>{data?.isActive ? <div className="status"><Icon color="var(--primary)" name="CircleFilled"/>{t("CUSTOMER.ACTIVE")}</div> : <div className="status"><Icon color="var(--dark-opacity-25)" name="CircleFilled"/>{t("CUSTOMER.PASSIVE")}</div>}</Link>
    },
    {
      key: "lastLoginDateAt",
      title: t("CUSTOMER.LAST_LOGIN_DATE_AT"),
      dataIndex: "lastLoginDateAt",
      render: (_, data) => <Link to={routeLink(data)}>{formatDate(data?.lastLoginDateAt,true,"DD.MM.YYYY HH:mm:ss",false)}</Link>
    },
  ];

  return (
    <div className="customerList">
      <Listing
        hasLoading={props?.hasLoading}
        columns={columns}
        data={props?.data}
        defaultPageSize={props?.defaultPageSize}
      />
    </div>
  );
};

export default List;
