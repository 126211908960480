import { useTranslation, Textarea, RadioScale } from "krl-alfred";
import { Controller } from "react-hook-form";

function ScoreList({ control, errors, product }) {
  const { t } = useTranslation();

  return (
    <div className="scoreList">
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][sendingOnTime]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
              <RadioScale
                  label={t("FORM.LABEL.SENDING_ON_TIME")}
                  id={`${product?.id}_sendingOnTime`}
                  onChange={onChange}
                  name={name}
                  value={value}
                  alignment="vertical"
                  valid={errors?.scores && errors?.scores[product?.id]?.sendingOnTime ? false : true}
              />
          )}
          rules={{required: true}}
        />
      </div>
        <div className="input-container">
        <Controller
          name={`scores[${product?.id}][packagingAndBoxCondition]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
              <RadioScale
                label={t("FORM.LABEL.PACKAGING_AND_BOX_CONDITION")}
                id={`${product?.id}_packagingAndBoxCondition`}
                onChange={onChange}
                name={name}
                value={value}
                alignment="vertical"
                valid={errors?.scores && errors?.scores[product?.id]?.packagingAndBoxCondition ? false : true}
              />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][completenessOfParts]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
              <RadioScale
                  label={t("FORM.LABEL.COMPLETENESS_OF_PARTS")}
                  id={`${product?.id}_completenessOfParts`}
                  onChange={onChange}
                  name={name}
                  value={value}
                  alignment="vertical"
                  valid={errors?.scores && errors?.scores[product?.id]?.completenessOfParts ? false : true}
              />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][productCleaningAndCosmetics]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
              <RadioScale
                  label={t("FORM.LABEL.PRODUCT_CLEANING_AND_COSMETICS")}
                  id={`${product?.id}_productCleaningAndCosmetics`}
                  onChange={onChange}
                  name={name}
                  value={value}
                  alignment="vertical"
                  valid={errors?.scores && errors?.scores[product?.id]?.productCleaningAndCosmetics ? false : true}
              />
          )}
          rules={{ required: true }}
        />
      </div>
      <div className="input-container">
        <Controller
          name={`scores[${product?.id}][note]`}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <Textarea
              onChange={onChange}
              value={value}
              name={name}
              width="100%"
              //label={t('FORM.LABEL.NOTE')}
              label={null}
              placeholder={t("FORM.INPUT.NOTE")}
              hasError={
                errors?.scores ? errors?.scores[product?.id]?.note : null
              }
              errorMessage={t("FORM.ERROR.NOTE")}
              resize="none"
              height="145px"
            />
          )}
        />
      </div>
    </div>
  );
}

export default ScoreList;
