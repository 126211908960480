import { Button, useTranslation, toast } from "krl-alfred";
import {Link, useNavigate} from "react-router-dom";
import React, { useState } from "react";
import Listing from "../../Listing";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import { deleteBox } from "../../../api/routes/box";

const List = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'))

  const routeLink = (record) => `/operations-management/cargo-boxes/${record?.id}`;

  const handleDelete = async (record) => {
    setSelectedBox(record)
    if(!showConfirmationModal){
      setShowConfirmationModal(true)
      return
    }
    setIsLoading(true);
    await deleteBox({id:selectedBox.id,userId:user.id}).then((resp) => {
      toast({
          hasClosing: true,
          title: t("INFO.DELETED_SUCCESSFULL")
      })
    }).catch(err => {
      toast({
          hasClosing: true,
          icon: 'Danger',
          variant: 'warning',
          title: err?.response?.data?.message
      })
    })
    .finally((final) => {
      setIsLoading(false);
      setShowConfirmationModal(false)
      props?.refetch()
    });
  };

  const columns = [
    {
      key: "id",
      title: t("LIST.ID"),
      dataIndex: "id",
      render: (_, data) => <Link to={routeLink(data)}>{data?.id}</Link>
    },
    {
      key: "name",
      title: t("CARGO_BOXES.BOX_NAME"),
      dataIndex: "name",
      render: (_, data) => <Link to={routeLink(data)}>{data?.name}</Link>
    },
    {
      key: "code",
      title: t("CARGO_BOXES.BOX_CODE"),
      dataIndex: "code",
      render: (_, data) => <Link to={routeLink(data)}>{data?.code}</Link>
    },
    {
      key: "width",
      title: t("CARGO_BOXES.WIDTH"),
      dataIndex: "width",
      render: (_, data) => <Link to={routeLink(data)}>{`${data?.width} cm`}</Link>
    },
    {
      key: "size",
      title: t("CARGO_BOXES.SIZE"),
      dataIndex: "size",
      render: (_, data) => <Link to={routeLink(data)}>{`${data?.size} cm`}</Link>
    },
    {
      key: "height",
      title: t("CARGO_BOXES.HEIGHT"),
      dataIndex: "height",
      render: (_, data) => <Link to={routeLink(data)}>{`${data?.height} cm`}</Link>
    },
    {
      key: "desi",
      title: t("CARGO_BOXES.DESI"),
      dataIndex: "desi",
      render: (_, data) => <Link to={routeLink(data)}>{data?.desi}</Link>
    },
    {
      key: "actions",
      title: null,
      fixed: "right",
      width: 100,
      render: (record) =>
      (
        <div className="actions">
          <Button
            onClick={() => handleDelete(record)}
            width="32px"
            variant="primary"
            tooltip={t("BUTTON.DELETE")}
            size="xsmall"
            icon="DeleteSmall"
            isLoading={isLoading}
          />
        </div>
      )
    },
  ];

  return (
    <div className="cargoBoxList">
      <Listing
        hasLoading={props?.hasLoading}
        columns={columns}
        data={props?.data}
        defaultPageSize={props?.defaultPageSize}
      />
      <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          contentTitle={t("INFO.ARE_YOU_SURE_DELETED_CARGO_BOX")}
          onPrimaryAction={handleDelete}
          isPrimaryButtonLoading={isLoading}
          primaryButtonText={t('BUTTON.DELETE')}
      />
    </div>
  );
};

export default List;
