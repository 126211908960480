import {Icon, useTranslation, Button, TextLink, Loader} from "krl-alfred";
import {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useSearchParams} from "react-router-dom";
import useOnClickOutside from "../../Hooks/useOnClickOutside";

const FilterDropdown = ({
                            handleClear,
                            onFilter,
                            children,
                            isLoading,
                            hasExport,
                            onExport
                        }) => {
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const ref = useRef(null);

    const [show,setShow] = useState(false)
    const [params,setParams] = useState([])
    const [hasLoadingSm,setHasLoadingSm] = useState(false)

    useEffect(() => {
        const filteredParams = []
        searchParams.forEach((value, key) => {
            if(!["defaultPageSize","index"].includes(key))
                filteredParams.push({[key]:value});
        });
        setParams(filteredParams)
        if(!isLoading)
            setHasLoadingSm(false)
    },[isLoading])

    const handleClearAll = async (e) => {
        setHasLoadingSm(true)
        await handleClear()
        await onFilter()
    }

    const handleFilter = async (e) => {
        setHasLoadingSm(true)
        setShow(false)
        await onFilter()
    }

    useOnClickOutside(ref, () => setShow(false))

    return (
        <div ref={ref} className={classNames("filterDropdown",{show,filled:params?.length > 0})}>
            <div className="dropdownHeader">
                <div className="dropdownToggle" onClick={(e) => {
                    if(e?.target?.closest("a")?.nodeName !== "A") {
                        setShow(!show)
                    }
                }}>
                    <div className="leftSide">
                        <div className="icon">
                            <Icon name="Filter" color="var(--dark-opacity-50)"/>
                        </div>
                        <strong className="title">{t("PAGE.FILTER")}</strong>
                        {params?.length > 0 && <span className="filterCount">{params?.length}</span>}
                    </div>
                    <div className="rightSide">
                        {isLoading && !show && hasLoadingSm && (
                            <div className="clearButton">
                                <Loader width="16px" height="16px" variant="primary"/>
                            </div>
                        )}
                        {params?.length > 0 && !show && !isLoading && (
                            <div className="clearButton">
                                <TextLink onClick={handleClearAll}>{t("BUTTON.CLEAR_ALL")}</TextLink>
                            </div>
                        )}
                        <div className="dropdownIcon"><Icon name="ArrowDownBig" color="var(--dark)"/></div>
                    </div>
                </div>
                {hasExport && (
                    <div className="exportWrapper">
                        <Button
                            onClick={onExport}
                            width="56px"
                            variant="inverted"
                            size="big"
                            icon="Download"
                            iconSize="1.5rem"
                        />
                    </div>
                )}
            </div>
            <div className="dropdownBody">
                <form onKeyDown={(e) => {
                    if (e.key === 'Enter')
                        handleFilter();
                }}>
                    {children}
                </form>
                <div className="dropdownFooter">
                    <Button width="96px" onClick={handleClear} variant="inverted" size="xsmall">Temizle</Button>
                    <Button width="96px" onClick={handleFilter} variant="primary" size="xsmall" type="submit">Filtrele</Button>
                </div>
            </div>
        </div>
    )
}

export default FilterDropdown