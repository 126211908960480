import { useTranslation, Modal, toast } from "krl-alfred"
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import SimpleBar from "simplebar-react";
import {orderItemDamageStatus, postOrderScore} from "../api/routes/order";
import ConfirmationModal from "./ConfirmationModal";
import OrderScoreForm from "../Forms/OrderScoreForm";

const OrderScoreModal = (props) => {
    const {
        show,
        setShow,
        data,
        refetch,
        order,
        damageType,
        hasProductDetail
    } = props

    const { t } = useTranslation()
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const submitRef = useRef(null)

    const {
        control,
        formState: { errors, isSubmitted, isValid, dirtyFields },
        watch,
        handleSubmit,
        setError,
        clearErrors,
        reset,
        setValue,
    } = useForm()
    
    const submit = async (values) => {
        let hasError = false;
        if(!showConfirmationModal){
            setShowConfirmationModal(true)
            setShow(false)
            return
        }
        setIsLoading(true)
        
        const validProductIds = []
        watch("productIds")?.map((item,key) => item && validProductIds.push(key) )
        
        await Promise.all(validProductIds.map(async product => {
            const selectedProduct = data.find(findingItem => findingItem.id === product);
            const selectedScores = values?.scores[product]

            await postOrderScore({
                customerId: order?.customerId,
                orderNumber: order?.orderNumber,
                sendingOnTime: parseInt(selectedScores?.sendingOnTime),
                packagingAndBoxCondition: parseInt(selectedScores?.packagingAndBoxCondition),
                completenessOfParts: parseInt(selectedScores?.completenessOfParts),
                productCleaningAndCosmetics: parseInt(selectedScores?.productCleaningAndCosmetics),
                note: selectedScores?.note,
            }).catch(err => {
                hasError = true
            })

            await orderItemDamageStatus({
                orderNumber: order?.orderNumber,
                productId: selectedProduct?.productId,
                orderItemId: selectedProduct?.id,
                variantId: selectedProduct?.variantId,
                isDamaged:damageType === 1,
            }).catch(err => {
                hasError = true
            })
            return product
        }));
        

        if(!hasError){
            toast({
                hasClosing: true,
                title: t("INFO.RECEIVED_COMPLETED")
            })
        }else{
            toast({
                hasClosing: true,
                icon: 'Danger',
                variant: 'warning',
                title: t("INFO.SOMETHING_WENT_ERROR")
            })
        }

        setShowConfirmationModal(false)
        setIsLoading(false)
        refetch()
    }

    useEffect(() => {
        if(show){
            if(hasProductDetail){
                reset({
                    productIds: null,
                })
                setValue(`productIds[${data[0]?.id}]`,true)
            }else{
                reset({
                    productIds: null,
                })
            }
        }
    },[show])

    return (
        <>
            <Modal
                titleAlignment="left"
                buttonsAlignment="horizontal"
                showHeaderBorder
                showFooterBorder
                showPrimaryButton
                showSecondaryButton
                showCloseIcon
                showBackIcon={false}
                size="small"
                title={damageType === 1 ? hasProductDetail ? t("INFO.IS_DAMAGED") : t("INFO.IS_DAMAGED_REPORT") : hasProductDetail ? t("INFO.IS_NOT_DAMAGED") : t("INFO.IS_NOT_DAMAGED_REPORT")}
                primaryButtonText={t('BUTTON.CONTINUE')}
                secondaryButtonText={t('BUTTON.GIVE_UP')}
                id="orderScoreModal"
                onClose={() => setShow(false)}
                onSecondaryAction={() => setShow(false)}
                show={show}
                onPrimaryAction={() => submitRef?.current?.click()}
                isPrimaryButtonLoading={isLoading}
                isPrimaryButtonDisabled={Object.keys(dirtyFields).length <= 0}
                customContent
                primaryButtonWidth="50%"
                secondaryButtonWidth="50%"
            >
                <SimpleBar autoHide={false}>
                    <div className="modalInnerContent">
                        <div className="customerDetail">
                            <strong className="title">{t("ORDER.ORDER_INFO")}</strong>
                            <ul className="detailList">
                                <li>
                                    <span className="label">{t("CUSTOMER.FIRST_AND_LAST_NAME")}: </span>
                                    <span
                                        className="value">{`${order?.shippingAddresses[0]?.firstName} ${order?.shippingAddresses[0]?.lastName}`}</span>
                                </li>
                                <li>
                                    <span className="label">{t("ORDER.ORDER_NUMBER")}: </span>
                                    <span className="value">{`${order?.orderNumber}`}</span>
                                </li>
                                <li>
                                    <span className="label">{t("ORDER.SHIPPING_ADDRESS")}: </span>
                                    <span className="value">
                                        {order?.shippingAddresses[0]?.streetAddress}<br/>
                                        {order?.shippingAddresses[0]?.county}/{order?.shippingAddresses[0]?.city}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <OrderScoreForm
                            onSubmit={handleSubmit(submit)}
                            control={control}
                            errors={errors}
                            isLoading={isLoading}
                            submitRef={submitRef}
                            productList={data}
                            hasProductDetail={hasProductDetail}
                        />
                    </div>
                </SimpleBar>
            </Modal>
            <ConfirmationModal
                show={showConfirmationModal}
                setShow={setShowConfirmationModal}
                contentTitle={damageType === 1 ? t("INFO.ARE_YOU_SURE_SAVE_DAMAGED_PRODUCT_INFO") : t("INFO.ARE_YOU_SURE_SAVE_NOT_DAMAGED_PRODUCT_INFO")}
                onPrimaryAction={handleSubmit(submit)}
                isPrimaryButtonLoading={isLoading}
                primaryButtonText={t('BUTTON.APPROVE')}
            />
        </>
    )
}

export default OrderScoreModal