import { Button, toast, useTranslation } from "krl-alfred";
import {Link, useNavigate} from "react-router-dom";
import Listing from "../../Listing";
import ConfirmationModal from "../../../Modals/ConfirmationModal";
import React, {useState} from "react";

const List = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmationModal,setShowConfirmationModal] = useState(false)
  const [isLoading,setIsLoading] = useState(false)

  const routeLink = (record) => {
    let respLink = `/product-management/products/${record?.productId}`;
    return respLink
  }

  const handleDeleteProduct = async (record) => {
    if(!showConfirmationModal){
      setShowConfirmationModal(true)
      return
    }
    toast({
      timer: 5000,
      hasClosing: true,
      icon: "Danger",
      variant: "warning",
      title: "Silinir gibi oldu ama burası da çalışmıyor, çünkü yapısı hazır değil. hazır olunca yapılcak :)",
    });
    setShowConfirmationModal(false)
  };

  let columns = [
    {
      key: "productId",
      title: t("PRODUCT.PRODUCT_ID"),
      dataIndex: "productId",
      render: (_, data) => <Link to={routeLink(data)}>{data?.productId}</Link>
    },
    {
      key: "medias",
      title: t("PRODUCT.PRODUCT_IMAGE"),
      dataIndex: "medias",
      render: (_, data) => <Link to={routeLink(data)}><img alt={data.name} className="imgFluid" width={50} src={data?.medias?.length > 0 ?data?. medias[0]?.url : "/logo.svg"} /></Link>
    },
    {
      key: "name",
      title: t("PRODUCT.PRODUCT_NAME"),
      dataIndex: "name",
      render: (_, data) => <Link to={routeLink(data)}>{data?.name}</Link>
    },
    {
      key: "variants",
      title: t("PRODUCT.STOCK_STATUS"),
      dataIndex: "variants",
      render: (_, data) => <Link to={routeLink(data)}>{data?.variants[0]?.salableQuantity}</Link>
    },
    {
      key: "actions",
      title: null,
      fixed: "right",
      width: 100,
      render: (record) =>
        <div className="actions">
          <Button
            onClick={() => handleDeleteProduct(record)}
            width="32px"
            variant="primary"
            size="xsmall"
            icon="DeleteSmall"
            tooltip={t("BUTTON.DELETE")}
          />
        </div>
    }
  ]

  return (
    <div className="productPageList">
      <Listing
        hasLoading={props?.hasLoading}
        columns={columns}
        data={props?.data}
        defaultPageSize={props?.defaultPageSize}
      />

      <ConfirmationModal
          show={showConfirmationModal}
          setShow={setShowConfirmationModal}
          contentTitle={t("INFO.ARE_YOU_SURE_DELETE_PRODUCT")}
          onPrimaryAction={handleDeleteProduct}
          isPrimaryButtonLoading={isLoading}
          primaryButtonText={t('BUTTON.I_APPROVE')}
      />
    </div>
  );
};

export default List;
