import { useParams } from "react-router-dom";

const VariantSerialNumberDetail = () => {
  const { productId } = useParams();

  return (
    <div>
      VariantSerialNumberDetail <br/>
      productId : {productId}
    </div>
  );
};

export default VariantSerialNumberDetail;
