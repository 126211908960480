import {useQuery} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {Loader, useTranslation, toast, TextLink} from "krl-alfred";
import {getProductDetail} from "../../../api/routes/product";
import Tabs from "./Tabs"

function ProductDetail(props) {

    const { productId } = useParams();
    const {t} = useTranslation();

    const navigate = useNavigate();

    const {data,isFetching} = useQuery({ queryKey: [`orderDetail_${productId}`,productId], retry:0, queryFn: () => getProductDetail({productId}) });

    return (
        <div id="productDetail">
            {isFetching ? (
                <div className="loadingPage">
                    <Loader width="32px" height="32px" variant="primary"/>
                </div>
            ) : (
                <>
                    <div className="rollbackWrapper">
                        <TextLink
                            size="small"
                            icon="ArrowSmallLeft"
                            onClick={() => navigate(-1)}
                        >
                            {t('MY_ACCOUNT.BACK_ALL_PRODUCTS')}
                        </TextLink>
                    </div>
                    <Tabs productDetail={data}/>
                </>
            )}
        </div>
    )
}

export default ProductDetail;