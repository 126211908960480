import { useTranslation, Modal, toast, CircleProgress } from "krl-alfred";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import SimpleBar from "simplebar-react";
import { getOrderDetail, sendDispatch, sendShipment } from "../api/routes/order";
import SendShipmentForm from "../Forms/SendShipmentForm";
import { updateItemsSerialNumber } from "../api/routes/orderItem";
import CargoBarcode from "./../Components/Pages/OrderDetail/CargoBarcode";
import JsBarcode from "jsbarcode";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const SendShipmentModal = (props) => {
  const { show, setShow, orderDetail, refetch } = props;

  const { t } = useTranslation();
  const [allStepsDone, setAllStepsDone] = useState(false);
  const [isLoading, setIsLoading] = useState({
    isSubmitLoading: false,
  });
  const [activeStep, setActiveStep] = useState(1);

  const submitRef = useRef(null);

  const steps = [
    {
      index: 1,
      text: t("ORDER.CARGO.STEPS.STEP1_TITLE"),
      textSm: t("ORDER.CARGO.STEPS.STEP1_DESC"),
    },
    {
      index: 2,
      text: t("ORDER.CARGO.STEPS.STEP2_TITLE"),
      textSm: t("ORDER.CARGO.STEPS.STEP2_DESC"),
    },
    {
      index: 3,
      text: t("ORDER.CARGO.STEPS.STEP3_TITLE"),
      textSm: t("ORDER.CARGO.STEPS.STEP3_DESC"),
    },
  ];

  const {
    control,
    formState: { errors, dirtyFields },
    watch,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm();

  const handleExportCargo = async (cargoKey,item=null) => {
    await JsBarcode("#barcode", cargoKey, {
      format: "CODE128",
    });
    const elementHTML = document.querySelector(`#printibleContent_custom`);
    await html2canvas(elementHTML).then(async (canvas) => {
      var imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF("landscape", "px", "a7");
      await doc.html("<img width='100%' src='" + imgData + "'/>", {
        callback: async function (doc) {
          // Save the PDF
          await doc.save(`kargo_${orderDetail?.orderNumber}_${item?.id}_${item?.productName}.pdf`);
        },
        filename: "Kargo Çıktısı",
        x: 10,
        y: 10,
        width: 200, //target width in the PDF document
        windowWidth: 750, //window width in CSS pixels
      });
    });
  };

  const submit = async (values) => {
    setIsLoading((prev) => ({ ...prev, isSubmitLoading: true }));

    const validProductIds = [];
    const validSerialNumbers = [];
    const boxIds = [];
    let hasError = false;

    values?.productIds?.map((item, key) => item && validProductIds.push(key));
    values?.serialNumber?.map(
      (item, key) =>
        !["", null, undefined].includes(item) &&
        validSerialNumbers.push({
          id: key,
          value: item,
        })
    );
    values?.boxIds?.map((item, key) => boxIds?.push(item?.value));

    switch (activeStep) {
      case 1:
        if (validProductIds?.length <= 0) {
          /*values?.productIds?.map((item, key) =>
            setError(`productIds[${key}]`, true)
          );*/
          setIsLoading((prev) => ({ ...prev, isSubmitLoading: false }));
          return;
        }

        await Promise.all(
          validSerialNumbers.map(async (serialNumber) => {
            const selectedProduct = orderDetail?.items?.find(
              (findingItem) => findingItem.id === serialNumber.id
            );
            if(String(selectedProduct.serialNumber) !== String(serialNumber.value)) {
              await updateItemsSerialNumber({
                orderNumber: orderDetail?.orderNumber,
                itemSerialNumbers: [
                  {
                    id: selectedProduct?.id,
                    sku: selectedProduct?.sku,
                    serialNumber: serialNumber.value,
                  },
                ],
              }).catch((err) => {
                setError(`serialNumber[${serialNumber.id}]`, {
                  message: err?.response?.data?.errors[0],
                });
                hasError = true;
              });
            }

            return serialNumber;
          })
        );
        if (!hasError) {
          clearErrors();
          setActiveStep(2);
        }
        break;
      case 2:
        setActiveStep(3);
        break;
      case 3:
        const sendedValues = {
          orderNumber: orderDetail?.orderNumber,
          providerType: values?.providerType?.value,
          orderItemId: validProductIds,
          cargoDirectionType: "Outgoing",
          quantity: values?.quantity?.value,
          boxIds: boxIds,
        };

        await sendShipment(sendedValues)
          .then(async (resp) => {
            await sendDispatch({
              orderNumber: orderDetail?.orderNumber,
              orderItemIds: validProductIds,
              quantity: values?.quantity?.value,
              providerType: values?.providerType?.value,
            })
                .then(async (resp) => {

                  if (values?.providerType?.value === "YurticiCargo") {
                    const toastVariables = {
                      title: t("INFO.SENDING_COMPLETED"),
                      icon: "CheckMark",
                      hasClosing: false,
                      variant: "primary",
                      alignment: "left",
                      showPrimaryButton: true,
                      showSecondaryButton: true,
                      secondaryButtonText: t("BUTTON.GIVE_UP"),
                      primaryButtonText: t("BUTTON.DOWNLOAD_BARCODE"),
                      isSecondaryButtonLoading: false,
                      onSecondaryAction: (e) => e,
                      onPrimaryAction: async (e) => {

                        toast({...toastVariables,isPrimaryButtonLoading: true});
                        await getOrderDetail(orderDetail?.orderNumber).then(async resp => {
                          const items = await resp?.items.filter(item => {
                            return item?.orderShipments?.find(
                                (cargoDetail) =>
                                    cargoDetail?.cargoDirectionType === 1 &&
                                    cargoDetail?.shippingCompanyId === 2 &&
                                    cargoDetail?.cargoKey
                            );
                          })

                          for (let i = 0; i < items.length; i++) {

                            const cargoKey = items[i].orderShipments?.find(
                                (cargoDetail) =>
                                    cargoDetail?.cargoDirectionType === 1 &&
                                    cargoDetail?.shippingCompanyId === 2 &&
                                    cargoDetail?.cargoKey
                            )?.cargoKey

                            await handleExportCargo(cargoKey,items[i])
                          }

                        }).catch(err => err)

                        toast({...toastVariables,isPrimaryButtonLoading: false});
                      },
                      children: t("INFO.SENDING_COMPLETED_DESC"),
                      primaryButtonWidth: "171px",
                      secondaryButtonWidth: "92px",
                    };

                    toast(toastVariables);
                  } else {
                    toast({
                      hasClosing: true,
                      title: t("INFO.SENDING_PROCESS_COMPLETED"),
                    });
                  }
                })
                .catch((err) => {
                  toast({
                    hasClosing: true,
                    icon: "Danger",
                    variant: "warning",
                    title: err?.response?.data?.message,
                  });
                });

          })
          .catch((err) => {
            toast({
              hasClosing: true,
              icon: "Danger",
              variant: "warning",
              title: err?.response?.data?.message,
            });
          });
        setShow(false);

        break;
      default:
        break;
    }

    setIsLoading((prev) => ({ ...prev, isSubmitLoading: false }));
    if(activeStep === 3) {
      refetch();
    }

  };

  const onPrimaryAction = () => {
    if (allStepsDone) {
      setActiveStep(1);
      setAllStepsDone(false);
      reset();
    } else {
      submitRef?.current?.click();
    }
  };

  const onSecondaryAction = () => {
    if (!isLoading?.isSubmitLoading) {
      if (activeStep === 1) {
        setShow(false);
      } else {
        setActiveStep((prev) => (prev === 1 ? 1 : prev - 1));
      }
    }
  };

  const isPrimaryButtonDisabled = () => {
    if (activeStep === 1) {
      const validProductIds = [];
      const validSerialNumbers = [];
      watch("productIds")?.map(
        (item, key) => item && validProductIds.push(key)
      );
      watch("serialNumber")?.map(
        (item, key) =>
          !["", null, undefined].includes(item) && validSerialNumbers.push(key)
      );

      return (
        (validProductIds.length <= 0 && validSerialNumbers.length <= 0) ||
        validProductIds.length !== validSerialNumbers.length
      );
    } else if (activeStep === 2) {
      return !watch("providerType") || !watch("quantity");
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (show) {
      setActiveStep(1);
      reset({
        boxIds: null,
        productIds: null,
        providerType: null,
        quantity: null,
        serialNumber: null,
      });
    }
  }, [show]);

  useEffect(() => {
    isPrimaryButtonDisabled();
  }, [watch()]);

  return (
    <>
      <Modal
        titleAlignment="left"
        buttonsAlignment="horizontal"
        showHeaderBorder
        showFooterBorder
        showPrimaryButton
        showSecondaryButton
        showCloseIcon
        showBackIcon={false}
        size="small"
        title={t("INFO.SENDING_PROCEDURES")}
        primaryButtonText={
          activeStep === 3
            ? t("BUTTON.CONFIRM_AND_FINISH")
            : t("BUTTON.CONTINUE")
        }
        secondaryButtonText={
          activeStep === 1 ? t("BUTTON.GIVE_UP") : t("BUTTON.GO_BACK")
        }
        id="sendShipmentModal"
        onClose={() => !isLoading?.isSubmitLoading && setShow(false)}
        onSecondaryAction={onSecondaryAction}
        show={show}
        onPrimaryAction={onPrimaryAction}
        isPrimaryButtonLoading={isLoading?.isSubmitLoading}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled()}
        customContent
        primaryButtonWidth="50%"
        secondaryButtonWidth="50%"
        hasCloseOnOutsideClick
      >
        <SimpleBar autoHide={false}>
          {!allStepsDone && (
            <CircleProgress steps={steps} activeStep={activeStep} />
          )}
          <div className="modalInnerContent">
            {activeStep === 3 && (
              <p className="infoText">{t("INFO.SENDING_TO_BE_COMPLETED")}</p>
            )}
            <div className="formWrapper">
              <div className="customerDetail">
                <strong className="title">{t("ORDER.ORDER_INFO")}</strong>
                <ul className="detailList">
                  <li>
                    <span className="label">
                      {t("CUSTOMER.FIRST_AND_LAST_NAME")}:{" "}
                    </span>
                    <span className="value">{`${orderDetail?.shippingAddresses[0]?.firstName} ${orderDetail?.shippingAddresses[0]?.lastName}`}</span>
                  </li>
                  <li>
                    <span className="label">{t("ORDER.ORDER_NUMBER")}: </span>
                    <span className="value">{`${orderDetail?.orderNumber}`}</span>
                  </li>
                  <li>
                    <span className="label">
                      {t("ORDER.SHIPPING_ADDRESS")}:{" "}
                    </span>
                    <span className="value">
                      {orderDetail?.shippingAddresses[0]?.streetAddress}<br/>
                      {orderDetail?.shippingAddresses[0]?.county}/{orderDetail?.shippingAddresses[0]?.city}
                    </span>
                  </li>
                  {activeStep === 3 && (
                    <li>
                      <span>{t("PRODUCT.PACKAGE_QUANTITY")}: </span>
                      <span className="value">{watch("quantity")?.label}</span>
                    </li>
                  )}
                </ul>
              </div>
              <SendShipmentForm
                onSubmit={handleSubmit(submit)}
                control={control}
                errors={errors}
                isLoading={isLoading}
                submitRef={submitRef}
                activeStep={activeStep}
                productList={orderDetail?.items}
                dirtyFields={dirtyFields}
                watch={watch}
                setValue={setValue}
                setIsLoading={setIsLoading}
                clearErrors={clearErrors}
              />
            </div>
          </div>
        </SimpleBar>
      </Modal>

      <div
        style={{
          opacity: "0",
          position: "absolute",
          userSelect: "none",
          pointerEvents: "none",
          left: "0",
          right: "0",
          overflow: "hidden",
          bottom: "0",
        }}
      >
        <CargoBarcode order={orderDetail} />
      </div>
    </>
  );
};

export default SendShipmentModal;
