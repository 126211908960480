import {Tabs as AlfredTabs, useTranslation} from "krl-alfred"
import {useState} from "react";
import ProductInfo from "./ProductInfo";
import Prices from "./Prices";
import Properties from "./Properties";
import Variants from "./Variants";
import Medias from "./Medias";

const Tabs = ({productDetail}) => {

    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)

    const handleChangeTab = (index) => {
        setActiveIndex(index)
    }

    const tabs = [
        {
            key : 0,
            title : "PRODUCT.PRODUCT_INFO",
            element : <ProductInfo productDetail={productDetail} />
        },
        {
            key : 1,
            title : "PRODUCT.PRICES",
            element : <Prices productDetail={productDetail} />
        },
        {
            key : 2,
            title : "PRODUCT.PROPERTIES",
            element : <Properties productDetail={productDetail} />
        },
        {
            key : 3,
            title : "PRODUCT.VARIANTS",
            element : <Variants productDetail={productDetail} />
        },
        {
            key : 4,
            title : "PRODUCT.MEDIAS",
            element : <Medias productDetail={productDetail} />
        }
    ]

    return (
        <div>
            <AlfredTabs activeIndex={activeIndex} onActive={handleChangeTab}>
                {tabs?.map(tab => (
                    <AlfredTabs.TabItem isDisabled={isLoading} title={t(tab.title)} key={tab.key}>
                        <div className="tabContentWrapper">
                            {tab.element}
                        </div>
                    </AlfredTabs.TabItem>
                ))}
            </AlfredTabs>
        </div>
    )
}

export default Tabs;