import { ProductCardCheckbox } from "krl-alfred"
import { Controller } from "react-hook-form"
import ScoreList from "./ScoreList"

const ProductList = ({
        control,
        data,
        errors,
        hasProductDetail
    }) => {
        
    return (
        <div className="productList">
            {
                data?.map((item, index) => (
                    <Controller
                        key={index}
                        control={control}
                        name={`productIds[${item?.id}]`}
                        defaultValue={false}
                        render={({ field: { onChange, value, name } }) => (
                            <div className="productCard">
                                <ProductCardCheckbox
                                    hasCheckbox={!hasProductDetail}
                                    disabled={hasProductDetail}
                                    title={item?.productName}
                                    name={name}
                                    contentAlignment="left"
                                    image={<img src={item?.pictureUrl} width="64px" alt={item?.productName} />}
                                    value={String(item?.id)}
                                    productChecked={value}
                                    checkboxId={`productCard_${item?.id}`}
                                    checked={value}
                                    size="small"
                                    productCardOnChange={onChange}
                                    valid={errors?.productIds ? !errors?.productIds[item?.id] : true}
                                    content={value && <ScoreList control={control} errors={errors} product={item}/>}
                                />
                            </div>
                        )}
                        rules={{ required: false }}
                    />
                ))
            }
        </div>
    )
}

export default ProductList