import {request} from "../request";

export const getOrders = async (params) => {
    const refactorParams = Object?.keys(params)?.length > 0 ? {...params} : null
    let productIds = "productIds=null"
    if(params?.productIds){
        productIds = JSON.parse(params?.productIds).map(item => item.value).join(",").split(",")?.map(item => "productIds=" + item).join("&");
        delete refactorParams.productIds
    }
    return await request({
        url: `console/Order/filter?${productIds}`,
        method: 'get',
        params: refactorParams,
        paramsSerializer: {
            indexes: true, // use brackets with indexes
        }
    })
}

export const getOrdersByOperation = async (params) => {
    const refactorParams = Object?.keys(params)?.length > 0 ? {...params} : null
    let productIds = "productIds=null"
    if(params?.productIds){
        productIds = JSON.parse(params?.productIds).map(item => item.value).join(",").split(",")?.map(item => "productIds=" + item).join("&");
        delete refactorParams.productIds
    }
    return await request({
        url: `console/Order/filter-by-operation?${productIds}`,
        method: 'get',
        params: refactorParams,
        paramsSerializer: {
            indexes: true, // use brackets with indexes
        }
    })
}

export const getOrderDetail = async (orderNumber) => await request({
    url: `console/Order/order-number/${orderNumber}`,
    method: 'get',
})

export const getOrderDetailByOperation = async (orderNumber) => await request({
    url: `console/Order/order-number-by-operation/${orderNumber}`,
    method: 'get',
})

export const confirmOrder = async (params) => await request({
    url: 'console/Order/confirm-order',
    method: 'post',
    params
})

export const unConfirmOrder = async (params) => await request({
    url: 'console/Order/unConfirm-order',
    method: 'post',
    params
})

export const sendShipment = async (data) => await request({
    url: 'console/Order/send-shipment',
    method: 'post',
    data
})

export const sendDispatch = async (data) => await request({
    url: 'console/Order/send-dispatch',
    method: 'post',
    data
})

export const updateOrderStatus = async (data) => await request({
    url: 'console/Order/update-order-status',
    method: 'post',
    data
})

export const updatePaymentStatus = async (data) => await request({
    url: 'console/Order/update-payment-status',
    method: 'post',
    data
})

export const startInsuranceSend = async (params) => await request({
    url: 'console/Order/start-insurance-send',
    method: 'post',
    params
})

export const postOrderScore = async (data) => await request({
    url: 'console/Order/post-order-score',
    method: 'post',
    data
})

export const orderItemDamageStatus = async (params) => await request({
    url: 'console/Order/order-item-damage-status',
    method: 'post',
    params
})

export const calendarFilter = async (params) => {
    const refactorParams = Object?.keys(params)?.length > 0 ? {...params} : null
    let productIds = ""
    if(params?.productIds){
        productIds ="productIds=" + JSON.parse(params?.productIds)?.value
        delete refactorParams.productIds
    }
    return await request({
        url: `console/Order/calendar-filter?${productIds}`,
        method: 'get',
        params: refactorParams,
        paramsSerializer: {
            indexes: true, // use brackets with indexes
        }
    })
}